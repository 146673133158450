<template>
  <div>
    <el-form class="query-form searchForm" size="small"
             @keyup.enter.native="getDataList(1,1)"
             ref="inputForm" :model="inputForm" label-width="90px">
      <el-form-item label="资源名称" prop="resourceName">
        <el-input v-model.trim="inputForm.resourceName"
                  placeholder="请输入资源名称(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="成文时间" prop="accidentTime" class="dateBox">
        <el-date-picker
            v-model="inputForm.accidentTime"
            type="daterange"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <span style="padding-left: 15px">
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
      </span>
      <span class="f_r" style="padding-bottom: 15px">
        <el-button size="small"
                   @click="exportData()">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
        <el-button icon="el-icon-download" size="small" @click="downloadData()">
          批量下载
        </el-button>
      </span>
    </el-form>

    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        height="calc(100vh - 410px)"
        class="table"
        :row-key="'id'"
        :header-cell-style="{background:'#F9DFDF'}"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="resourceType" label="资源类型" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $dictUtils.getDictLabel("resource_type", scope.row.resourceType, '-') }}
        </template>
      </el-table-column>
      <el-table-column prop="resourceTypeCode" label="类型编码" show-overflow-tooltip></el-table-column>
      <el-table-column prop="resourceFoshot" label="资源简称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="resourceDesc" label="资源描述" show-overflow-tooltip></el-table-column>
      <el-table-column prop="resourceTitle" label="主题标题" show-overflow-tooltip></el-table-column>
      <el-table-column prop="resourceSource" label="资源来源" show-overflow-tooltip></el-table-column>
      <el-table-column prop="writtenTime" label="成文时间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.writtenTime | formatDate('YYYY-MM-DD') }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="addData(scope.row)">
            详情
          </el-button>
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="downloadData(scope.row)">
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <!--查看-->
    <ViewForm ref="viewForm"></ViewForm>
  </div>
</template>

<script>

import ViewForm from "@/views/modules/dyyg/gather/resource/components/viewForm.vue";

export default {
  name: "publications",
  components: {ViewForm},
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },
    listType: {
      type: String,
      required: true,
    },
    moduleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputForm: {
        resourceName: '',
        accidentTime: [],
        startDate: '',
        endData: '',
      },

      dataListSelect: [],
      loading: false,
      dataList: [],

      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.pageNo = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      if (this.inputForm.accidentTime && this.inputForm.accidentTime.length != 0) {
        this.inputForm.startDate = this.inputForm.accidentTime[0]
        this.inputForm.endData = this.inputForm.accidentTime[1]
      } else {
        this.inputForm.startDate = ''
        this.inputForm.endData = ''
      }
      this.searchRecord = {
        ...this.inputForm,
        dataId: this.id,
        current: this.current,
        size: this.size,
        fileType: this.listType,
      }
      let fechUrl = this.api.collection.collectionfileListDyygOtherFile
      this.$axios(fechUrl, this.searchRecord, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //num详情
    addData(row) {
      this.$refs.viewForm.init(row.resourceId, this.listType, this.moduleId);
    },

    //批量导出
    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.resourceId
        }
      })
      let data = {
        ids: ids,
        fileType: this.listType,
      }
      let fileName = this.$dictUtils.getDictLabel("resource_type", this.listType, '-')
      this.exportExcel(this.api.collection.collectionfileExportOtherFile, data, fileName, 'get')
      this.getDataList('', 1)
    },

    //下载
    downloadData(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      if (row) {
        if (row.fileCount) {
          ids = [
            row.resourceId
            // {
            //   repairId: row.id,
            //   xuhao: row.number,
            //   dataId: this.id,
            // }
          ]
        }
      } else {
        this.dataListSelect.forEach(item => {
          if (item) {
            if (item.fileCount) {
              ids.push(
                  item.resourceId
                  // {
                  //   repairId: item.id,
                  //   xuhao: item.number,
                  //   dataId: this.id,
                  // }
              )
            }
          }
        })
      }
      if (ids.length == 0) {
        this.$message.error('暂无附件！')
        return
      }
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.collectionfileDownloadOtherFile, {
        ids,
        fileType: this.listType,
      }, 'get', 'blob').then((res) => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        let filename = this.$dictUtils.getDictLabel("resource_type", this.listType, '-')
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style lang="scss" scoped>
.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 40%;
  }

  .dateBox.el-form-item--small.el-form-item {
    display: inline-block;
    width: 40%;
  }
}
</style>